import React, { useState } from 'react';
import { Icon } from 'react-ess-components';
import classnames from 'classnames';
import { isValid, parseISO } from 'date-fns';

import { CounterType, ICounter, ICounterOverviewData, IDayPlanning, IInterval, IShift, PlanningType } from '../../../../../models';
import { date, translations } from '../../../../../utils';
import { formatFromUntilTime } from '../../../../../utils/date';
import Total from './Total';

interface IProps {
  days: ICounterOverviewData[];
  columns: string[];
}

export default function Week({ days, columns }: IProps) {
  const [expandedDays, setExpandedDays] = useState([]);

  const renderRow = (item: ICounterOverviewData, i: number) => {
    const hasShifts = item.initialPlanning?.find(planning => planning.shifts.length) && item.currentPlanning?.find(planning => planning.shifts.length);
    const isExpanded = expandedDays.includes(item.day);

    const expand = () => {
      if (isExpanded) return setExpandedDays([...expandedDays.filter(day => day !== item.day)]);
      return hasShifts && setExpandedDays([...expandedDays, item.day]);
    };

    const className = classnames('row', { 'last-day': i === days.length - 1, 'pointer': hasShifts });

    return (
      <tr key={item.day} className={className}>
        <td className="left-align date-td">
          <button className="stripped-button td-button" style={{ width: '100%' }} onClick={expand} title={translations.getLabel(isExpanded ? 'lblCollapse' : 'lblExpand')}>
            <div className="date">
              <div className="button-group">{date.format(item.day, 'EE dd/MM')}
                <Icon color="primary" tag={isExpanded ? 'ChevronUpIcon' : 'ChevronDownIcon'} title={translations.getLabel(isExpanded ? 'lblCollapse' : 'lblExpand')} />
              </div>
            </div>
          </button>
        </td>
        <>
          {columns.includes(PlanningType.TargetPlanning) && <td className="planning">
            <button className="stripped-button td-button" style={{ width: '100%' }} onClick={expand} title={translations.getLabel(isExpanded ? 'lblCollapse' : 'lblExpand')}>
              {item.initialPlanning?.map((planning) => renderPlanning(planning, isExpanded))}
            </button>
          </td>}
          {columns.includes(PlanningType.ActualPlanning) && <td className="planning">
            <button className="stripped-button td-button" style={{ width: '100%' }} onClick={expand} title={translations.getLabel(isExpanded ? 'lblCollapse' : 'lblExpand')}>
              {item.currentPlanning?.map((planning) => renderPlanning(planning, isExpanded))}
            </button>
          </td>}
          {columns.includes(PlanningType.TimeRegistration) && <td>
            <button className="stripped-button td-button" style={{ width: '100%' }} onClick={expand} title={translations.getLabel(isExpanded ? 'lblCollapse' : 'lblExpand')}>
              {item.timeRegistrationHours}
            </button>
          </td>}
          {columns.includes(PlanningType.TickingList) && (
            <td>
              <button className="stripped-button td-button" style={{ width: '100%' }} onClick={expand} title={translations.getLabel(isExpanded ? 'lblCollapse' : 'lblExpand')}>
                {
                  !isExpanded ?
                    <>
                      {item.tickingList[0].time}
                      {
                        item.tickingList.length >= 2
                        &&
                        <>
                          {!!item.tickingList[0].time && !!item.tickingList[item.tickingList.length - 1].time && <>&#32;&#8594;&#32;</>}
                          {item.tickingList[item.tickingList.length - 1].time}
                        </>
                      }
                    </>
                    :
                    item.tickingList.map((ticking, i) => <p key={`${ticking.time}-${i}`}>{ticking.time} <span className="small-text">{ticking.label}</span></p>)
                }
              </button>
            </td>
          )}
        </>
        {item.counters?.map(renderValue)}
      </tr >
    );
  };

  const renderPlanning = (planning: IDayPlanning, isExpanded?: boolean) => planning.shifts.map((shift) => renderShift(shift, isExpanded));

  const renderShift = (shift: IShift, isExpanded?: boolean) => {
    if (shift.startDate && shift.endDate && isValid(parseISO(shift.startDate)) && isValid(parseISO(shift.endDate))) {
      return (
        <div key={shift.id} className="shift">
          <p className="shift-name">{shift.shiftName}</p>
          {
            isExpanded
              ?
              shift.intervals.map(renderInterval)
              :
              <p>{formatFromUntilTime(date.format, { startDate: parseISO(shift.startDate), endDate: parseISO(shift.endDate) })}</p>
          }
        </div>
      );
    }
    return null;
  };

  const renderInterval = (interval: IInterval, i: number) => (
    <div className="interval" key={`interval${i}`}>
      <p><i>{interval.timeType}</i></p>
      <p>{`${date.format(interval.startDate, 'HH:mm')} - ${date.format(interval.endDate, 'HH:mm')}`}</p>
    </div>
  );

  const renderValue = (counter: ICounter) => <td key={counter.wcfId} className="counter">{renderTime(counter)}</td>;

  const renderTime = ({ value, type }: ICounter) => {
    if(type === CounterType.Days) {
      return `${value.days} ${translations.getLabel(value.days === 1 ? 'lblDay' : 'lblDays')}`;
    }
    const minutes = value.minutes?.toString().padStart(2, '0');
    const hours = value.hours?.toString().padStart(2, '0');
    return `${hours}${translations.getLabel('lblH')}${minutes}`;
  };

  return (
    <>
      {days.map(renderRow)}
      {days.length > 0 && <Total counterOverviews={days} columnCount={columns.length} />}
    </>
  );
}
