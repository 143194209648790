import React from 'react';
import classNames from 'classnames';

import { counters } from '../../../../../../../shared/utils';
import { CounterType, ICounter, ICounterOverviewData, ICounterValue } from '../../../../../models';
import { translations } from '../../../../../utils';

interface IProps {
  counterOverviews: ICounterOverviewData[];
  isFinalTotal?: boolean;
  columnCount: number;
}

export default function Total({ counterOverviews, isFinalTotal, columnCount }: IProps) {
  const getTotal = ({ type }: ICounter, i: number) => {
    const { hours, minutes, days } = counterOverviews.reduce((sum, overview) => counters.sumReducer(sum, overview, i), { hours: 0, minutes: 0, days: 0 } as ICounterValue);

    const calculatedHours = (hours + Math.floor(minutes / 60)) || 0;
    const calculatedMinutes = (minutes % 60) || 0;

    const total = type === CounterType.Days ? `${days} ${translations.getLabel(days === 1 ? 'lblDay' : 'lblDays')}` : `${calculatedHours.toString().padStart(2, '0')}${translations.getLabel('lblH')}${(calculatedMinutes.toString().padStart(2, '0'))}`;
    return <td key={`total${i}`} className="th">{total}</td>;
  };

  const className = classNames({ 'final-total': isFinalTotal }, 'thead total');

  const getFillers = () => {
    const fillers = [];
    for (let i = 0; i < columnCount; i++) fillers.push(<td key={i} className="th" />);
    return fillers;
  };

  return (
    <tr className={className}>
      <td className="th left-align">{translations.getLabel(isFinalTotal ? 'lblTotal' : 'lblWeekTotal')}</td>
      {getFillers()}
      {
        counterOverviews[0]?.counters?.map(getTotal)
      }
    </tr>
  );
}

Total.defaultProps = {
  isFinalTotal: false,
};
